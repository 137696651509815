import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost } from '../../../../API/request';
import { formatPhoneNumber } from '../../../../help/telephone-mask';
import { initError, initValues } from './addMasterInitForm';
import { useShowAlert } from '../../../../hooks/useShowAlers.ts';

const useAddOutsideMaster = () => {
  const navigate = useNavigate();
  const postU = usePost();
  const getU = useGet();
  const { alert, showAlert } = useShowAlert();

  const [values, setValues] = useState(initValues);
  const [errors, setErrors] = useState(initError);
  const [cities, setCities] = useState([]);
  const [tags, setTags] = useState([]);

  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });

    if (
      event.target.name === 'generalCompensation' &&
      event.target.value > 100_000
    ) {
      setValues({ ...values, [event.target.name]: '100000' });
    }
    if (event.target.name === 'bet' && event.target.value > 100) {
      setValues({ ...values, [event.target.name]: '100' });
    }
    if (event.target.name === 'lift_compensation' && event.target.value > 100) {
      setValues({ ...values, [event.target.name]: '100' });
    }
  };

  const handleChangePhone = (event) => {
    const formattedNumber = formatPhoneNumber(event.target.value);
    if (formattedNumber.length > 16) return;
    setValues({ ...values, phone: formattedNumber });
    setErrors({ ...errors, phone: false });
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };
    let alertText = null;

    const changeAlertText = (text, field) => {
      if (validComplete) validComplete = false;
      if (field) {
        formErrors[field] = true;
      }
      if (!alertText) {
        alertText = text;
      }
    };

    if (+values.currentLift < 0 && values.currentLift !== '') {
      changeAlertText('Поле "Средний подъем" не должно быть отрицательным');
    }

    if (values.username.length === 0) {
      changeAlertText('Поле User Name не должно быть пустым', 'username');
    }

    if (values.fio.length === 0) {
      changeAlertText('Поле FIO не должно быть пустым', 'fio');
    }

    if (values.phone.length === 0) {
      changeAlertText('Поле phone number не должно быть пустым', 'phone');
    }

    if (values.telegram.length === 0 && values.whatsApp.length === 0) {
      changeAlertText(
        'Поле whatsApp или telegram не должно быть пустым',
        'telegram'
      );
      changeAlertText(
        'Поле whatsApp или telegram не должно быть пустым',
        'whatsApp'
      );
    }

    if (!values.generalCompensation) {
      changeAlertText(
        'Поле Общая компенсация не должно быть пустым',
        'generalCompensation'
      );
    } else if (
      values.generalCompensation &&
      Number(values.generalCompensation) > 100_000
    ) {
      changeAlertText(
        'Поле Общая компенсация не должно превышать 100 000',
        'generalCompensation'
      );
    }

    if (values.beginSchedule.length === 0) {
      changeAlertText('Поле Дата не должно быть пустым', 'beginSchedule');
    }

    if (!values.bet) {
      changeAlertText('Поле Процент не должно быть пустым', 'bet');
    } else if (values.bet && Number(values.bet) > 100) {
      changeAlertText('Поле Процент не должно превышать 100', 'bet');
    }

    if (!values.lift_compensation) {
      changeAlertText(
        'Поле Процент с допродаж не должно быть пустым',
        'lift_compensation'
      );
    } else if (
      values.lift_compensation &&
      Number(values.lift_compensation) > 100
    ) {
      changeAlertText(
        'Поле Общая компенсация не должно превышать 100',
        'lift_compensation'
      );
    }

    if (values.cities.length === 0) {
      changeAlertText('Поле Города не должно быть пустым', 'cities');
    }

    if (alertText) showAlert('error', alertText);

    setErrors(formErrors);
    return validComplete;
  };

  const createUserParams = (id) => {
    if (!id) return;
    const param = {
      master_id: id,
      schedule:
        values.schedule === 'Без ограничений' ? undefined : values.schedule,
      beginSchedule: values.beginSchedule,
      timeBegin: values.timeBegin,
      timeEnd: values.timeEnd,
      cities: values.cities.join(','),
      tags: values.tags.length === 0 ? undefined : values.tags.join(','),
      bet: values.bet,
      fio: values.fio,
      generalCompensation: values.generalCompensation,
      lift_compensation: values.lift_compensation,
      IgnoreTimeRange: values.full_time,
      Telegram: values.telegram !== '' ? values.telegram : undefined,
      WhatsApp: values.whatsApp !== '' ? values.whatsApp : undefined,
      phone:
        values.phone.length > 16
          ? values.phone.substring(0, values.phone.length - 1)
          : values.phone,
      currentLift: values.currentLift === '' ? undefined : values.currentLift
    };

    postU('admin/users/freelance/param', param)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Мастер добавлен');
          setValues(initValues);
          navigate('/app/masters');
        } else {
          throw new Error(resp.message);
        }
      })
      .catch((err) => {
        showAlert(
          'error',
          `Ошибка создания параметров пользователя: ${err.message}`
        );
      });
  };

  const submit = () => {
    if (!validate()) return;
    setSubmitDisabled(true);

    postU('admin/users', {
      username: values.username,
      role: 'freelance_master'
    })
      .then((resp) => {
        if (resp.status === 'success' && resp.data.user.id) {
          createUserParams(resp.data.user.id);
        } else {
          throw new Error(resp.message);
        }
      })
      .catch((err) => {
        if (
          err.message === 'Имя пользователя и почта должны быть уникальными'
        ) {
          setValues((prev) => ({ ...prev, username: '' }));
          setErrors((prev) => ({ ...prev, username: true }));
        }
        showAlert('error', `Ошибка создания пользователя: ${err.message}`);
      })
      .finally(() => setSubmitDisabled(false));
  };

  const fetchRegion = () => {
    return getU('region').then((resp) => {
      if (resp.status === 'success') {
        return resp.data.region;
      }
    });
  };

  const fetchTicketCategory = () => {
    return getU('ticket_category').then((resp) => {
      if (resp.status === 'success') {
        return resp.data.category;
      }
    });
  };

  useEffect(() => {
    fetchRegion()
      .then((regions) => setCities(regions.map((city) => city.region_name)))
      .catch((err) =>
        showAlert(
          'error',
          `Произошла ошибка при загрузке городов: ${err.message}`
        )
      );

    fetchTicketCategory()
      .then((category) => setTags(category.map((tag) => tag.category_name)))
      .catch((err) =>
        showAlert(
          'error',
          `Произошла ошибка при загрузке навыков: ${err.message}`
        )
      )
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  return {
    handleChange,
    handleChangePhone,
    submit,
    isLoaded,
    submitDisabled,
    cities,
    tags,
    alert,
    values,
    errors,
    setValues
  };
};

export { useAddOutsideMaster };
